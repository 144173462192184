// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import helpers from "@/helpers";
import { Adesione } from "@/models/carrello/adesione";
import { anagraficaPacchettiCategorie } from "@/models/carrello/categoria";
import { datiViaggio, totaliCarrello } from "@/models/carrello/datiViaggio";
import { CarrelloInfoProdotto } from "@/models/carrello/infoProdotto";
import { ParametriCarrelloItem } from "@/models/carrello/parametriCarrello";
import { parametriDichiarazioni } from "@/models/carrello/parametriDichiarazioni";
import { parametriPreventivatoreItem } from "@/models/carrello/parametriPreventivatore";
import { PartecipantiCarrello } from "@/models/carrello/partecipantiCarrello";
import { associaDistributoriPacchettiExtended } from "@/models/carrello/prodotti";
import { RigaCarrello, RigaCarrelloInfo } from "@/models/carrello/rigaCarrello";
import { TextValueItem, TextValueExtended } from "@/models/TextValueItem";
import api from "@/services/api";
import moment from "moment";
import { Component, Prop, Watch } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
import Vue from 'vue';

const _ENDPONT_TOTALI = api.endpoint.CARRELLO.TOTALI,
	_ENDPONT_COMPLEMENTARI = api.endpoint.CARRELLO.COMPLEMENTARI,
	_ENDPONT_CARRELLO = api.endpoint.CARRELLO.CARRELLO,
	_ENDPONT_DATI_PRATICA = api.endpoint.CARRELLO.DATI_PRATICA,
	_ENDPONT_MODIFICA_PRATICA = api.endpoint.CARRELLO.MODIFICA_PRATICA,
	_ENDPONT_INFO_PRODOTTO = api.endpoint.CARRELLO.INFO_PRODOTTO;

@Component({

})
export default class modificaPraticaPage extends baseContextComponent {
	titleInfoProdotto: string = "Informazioni sul Prodotto";

	title: string = "Informazioni Viaggio";
	subtitle: string = "Compila i campi sottostanti";
	subtitleRed: string = "per scoprire la polizza adatta alle tue esigenze";

	destinazioni: TextValueExtended[] = [];
	nazionalita: TextValueItem[] = [];
	
	parametriItem: parametriPreventivatoreItem = new parametriPreventivatoreItem();

	datiViaggio: datiViaggio = new datiViaggio();
	praticaInErrore: Boolean = false;

	prodottoSelezionato: CarrelloInfoProdotto = null;
	isOld: boolean = false;
	note: string = null;
	rifPratica: string = null;

	aggiungiACarrelloClick: boolean = false;

	inizioViaggio: Date = helpers.getToday();
	fineViaggio: Date = helpers.getToday();

	inizioViaggioMin: Date = null; // helpers.getToday();
	inizioViaggioMax: Date = null; //helpers.getMaxDate();

	fineViaggioMin: Date = null; // helpers.getToday();
	fineViaggioMax: Date = null; // helpers.getMaxDate();

	importoMin: number = 0;
	importoMax: number = 99999999999;

	postiMinimi: number = 1;
	postiMassimi: number = 999;

	complementari: any[] = [];
	nonCalcolarePreventivo: boolean = false;

	helpers: any = helpers;

	timeoutClick: any = null;

	rightBoxTotali: number = 6;

	codicePromozionale: string = "";
	showCodicePromozionale: boolean = true;
	showPacchettoDetail: boolean = false;
	cannotChangeParams: boolean = true;

	tesseraSingola: boolean = false;
	hideTesseraSingola: boolean = false;

	get totaleComplessivo() {
		if (!this.datiViaggio.totali) return {};
		var totali = this.creaInfoTotali();
		return totali["tot"];
	}

	get praticaID(): number {
		return this.$route && this.$route.params && this.$route.params.id
			? Number.parseInt(this.$route.params.id)
			: null;
	}

	getTestoCarrello(tipoID) {
		var t = (this.prodottoSelezionato ? this.prodottoSelezionato.testiCarrello || [] : []).find(obj => {
			return obj.tipoID == tipoID;
		});
		return t && t.testo ? t.testo : helpers.getTipoTestoCarrelloDefault(tipoID);
	}

	importoViaggioAbilitato() {
		if (!this.prodottoSelezionato) return true;

		if (helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.importoViaggioObbligatorio)) return true;
		
		if( this.secondoImportoAbilitato())
			return true;
		
		return false;
	}


	secondoImportoAbilitato() {
		if (!this.prodottoSelezionato) return true;

		if (helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.applicazioneImbarcazione)) return true;
		return false;
	}

	importoViaggioDisabled(){
		if( !this.secondoImportoAbilitato())
			return false;

		if( this.parametriItem.secondoImporto && this.parametriItem.secondoImporto > 0 )
			return true;
		return false;
	}

	secondoImportoDisabled(){
		if( !this.secondoImportoAbilitato())
			return true;

		if( this.parametriItem.importoViaggio && this.parametriItem.importoViaggio > 0 )
			return true;
		return false;
	}

	alloggiAbilitato() {
		if (!this.prodottoSelezionato) return true;

		if (helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.applicazioneAlloggio)) return true;
		return false;
	}

	async created() {
		var idPratica = this.praticaID;
		
		var datiPratica = await api.get(`${_ENDPONT_DATI_PRATICA}/${idPratica}`) as Adesione;

		var datiProdotto = await api.get(`${_ENDPONT_INFO_PRODOTTO}/${datiPratica.pacchettoID}?distributoreID=${datiPratica.distributoreID}`);

		if( !datiProdotto.pacchettoID ){
			this.praticaInErrore = true;
			return;
		}
		datiPratica.isOld && (this.isOld = true);
		this.initDatiViaggio(datiPratica, datiProdotto);
		this.prodottoSelezionato = datiProdotto;

		this.parametriItem = this.datiViaggio.parametri;

		//this.nonCalcolarePreventivo = true;
		this.getData();
		this.initModifica();
		this.complementari = this.$refs.complementari as any[];
	}

	mounted() {
		var divScroll = document.getElementsByClassName("app-content")[0];
		divScroll.addEventListener("scroll", this.handleScroll);
	}

	beforeDestroy() {
		var divScroll = document.getElementsByClassName("app-content")[0];
		divScroll.removeEventListener("scroll", this.handleScroll);
	}

	get textPremioPersona() {
		//return this.alloggiAbilitato() ? "Premio per Alloggio" : "Premio per Persona";
		return "Premio per Persona";
	}

	initDatiViaggio(datiPratica: Adesione, datiProdotto){

		var d = this.datiViaggio,
			prod = datiPratica;

		d.note = prod.note;
		d.pacchettoID = prod.pacchettoID;
		d.riferimentoPratica = prod.riferimentoPratica;
		d.tesseraSingola = false;
		d.immaginePartecipanti = prod.imageFilePartecipanti || "";
		//d.complementari = prod.complementari || [];
		
		var pCollegati = /*prod.dati.prodottiCollegati ||*/ "",
			pCollegatiArray = pCollegati ? JSON.parse(pCollegati) || [] : [];
		// esempio array prod collegati -> "[{\"PacchettoID\":769,\"DestinazioneID\":\"ITA\"}]"
		
		d.prodottiCollegati = pCollegatiArray;
		
		d.parametriDichiarazione.checkPolizza = true;
		d.parametriDichiarazione.checkPrivacy = true;
		d.parametriDichiarazione.adeguatezza = true;

		var partecipanti = prod.partecipantiElenco;
		//var part = partecipanti ? JSON.parse(atob(partecipanti)) || []: [];
		var part = partecipanti ? JSON.parse(partecipanti) || []: [];
		d.partecipanti = part;

		d.parametri.codicePromo = prod.codiceCoupon;
		d.parametri.destinazioneStato = prod.destinazioneID; // da rivedere
		d.parametri.fineViaggio = prod.fineViaggio;
		d.parametri.inizioViaggio = prod.inizioViaggio;
		d.parametri.importoViaggio = prod.importoViaggio;
		d.parametri.nazionalita = prod.nazionalitaID;
		d.parametri.numeroPartecipanti = prod.numeroPartecipanti;

		d.ulterioriDati = prod.datiAggiuntivi ? JSON.parse( atob(prod.datiAggiuntivi)) : [];


		var tot = ""/*prod.dati.infoTotali*/;
		var totali = tot ? JSON.parse(atob(tot)) || []: [];
		d.totali = totali ? totali.prodotto : {};

		d.itemID = datiPratica.itemID;
		d.inModifica = true;
	}

	initModifica() {
		this.parametriItem = this.datiViaggio.parametri;

		var dest = this.datiViaggio.parametri.destinazioneStato;
		if (typeof dest == "number") this.datiViaggio.parametri.destinazioneStato = this.destinazioni.find(obj => obj.value == (dest as any)) as any;

		var naz = this.datiViaggio.parametri.nazionalita;
		if (typeof naz == "number") this.datiViaggio.parametri.nazionalita = this.nazionalita.find(obj => obj.value == naz) as any;

		this.$root.$emit("updatePartecipanti", true);
		this.$root.$emit("updateDichiarazioni", true);
		this.$root.$emit("updateUlterioriDati", true);

		if( this.alloggiAbilitato() ){
			this.parametriItem.numeroAlloggi = 1;
		}

		this.note = this.datiViaggio.note || "";
		this.rifPratica = this.datiViaggio.riferimentoPratica || "";

		setTimeout(() => {
			this.nonCalcolarePreventivo = false;
			this.calcolaPreventivo();
		}, 800);
	}

	getData() {
		var self = this;

		self.initDate();

		self.destinazioni = this.prodottoSelezionato.destinazioni.map(function (obj) {
			var a = new TextValueExtended();
			//a.code = obj.alphaCode3;
			a.value = obj.code;
			a.text = obj.nazione;
			return a;
		});

		self.nazionalita = this.prodottoSelezionato.nazionalita.map(function (obj) {
			var a = new TextValueExtended();
			a.value = obj.codice;
			a.text = obj.nazione;
			return a;
		});
	}

	updateParametri(nonCalcolarePreventivo) {
		// this.datiViaggio.parametri = this.parametriItem;
		if (this.datiViaggio.totali && !nonCalcolarePreventivo)
			// se ho totali significa che ho già chiamato preventivo altre volte -> al change di ogni campo richiamo preventivo
			this.calcolaPreventivo();
	}

	updateParametriNoCalcolo(nonCalcolarePreventivo) {
		this.datiViaggio.parametri = this.parametriItem;
		this.setDatiViaggio(this.datiViaggio);
	}

	updateImportoViaggio() {
		if (!this.importoViaggioAbilitato()) {
			this.parametriItem.importoViaggio = 0
			this.updateParametri(false);
			return;
		}
		var val = parseFloat(this.datiViaggio.parametri.importoViaggio as any),
			impMin = parseInt(helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["ParametroViaggio"], "ImportoMinimo")) || 0,
			impMax = parseInt(helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["ParametroViaggio"], "ImportoMassimo")) || null;
		if (val < 0) val = 0;
		if (impMin && val < impMin) {
			var msg = "L'importo impostato supera il minimo di " + kendo.toString(impMin, "c");
			this.$bvToast.toast(msg, { variant: "warning", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			val = impMin;
		}
		if (impMax && val > impMax) {
			var msg = "L'importo impostato supera il massimo di " + kendo.toString(impMax, "c");
			this.$bvToast.toast(msg, { variant: "warning", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			val = impMax;
		}
		this.datiViaggio.parametri.importoViaggio = val;
		this.updateParametri(false);
	}

	updateSecondoImporto() {
		if (!this.secondoImportoAbilitato()) {
			this.updateParametri(false);
			return;
		}
		var val = parseFloat(this.datiViaggio.parametri.secondoImporto as any),
			impMin = parseInt(helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["ParametroViaggio"], "ImportoMinimo")) || 0,
			impMax = parseInt(helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["ParametroViaggio"], "ImportoMassimo")) || null;
		if (val < 0) val = 0;
		if (impMin && val < impMin) {
			var msg = "L'importo impostato supera il minimo di " + kendo.toString(impMin, "c");
			this.$bvToast.toast(msg, { variant: "warning", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			val = impMin;
		}
		if (impMax && val > impMax) {
			var msg = "L'importo impostato supera il massimo di " + kendo.toString(impMax, "c");
			this.$bvToast.toast(msg, { variant: "warning", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			val = impMax;
		}
		this.datiViaggio.parametri.secondoImporto = val;
		this.updateParametri(false);
	}

	updatePartecipantiElenco() {
		this.setDatiViaggio(this.datiViaggio);
	}

	setNumeroPartecipanti(numero) {
		this.datiViaggio.parametri.numeroPartecipanti = numero;
		this.updateNumeroPartecipanti();
	}

	updateNumeroPartecipanti() {
		var val = parseInt(this.datiViaggio.parametri.numeroPartecipanti as any),
			postiMinimi = parseInt(helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["ParametroViaggio"], "PostiMinimi")) || 1,
			postiMassimi = parseInt(helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["ParametroViaggio"], "PostiMassimi")) || null;

		if (val < 0) val = 0;
		if (postiMinimi && val < postiMinimi) {
			val = postiMinimi;
		}
		if (postiMassimi && val > postiMassimi) {
			var msg = "L'importo impostato supera il massimo di " + kendo.toString(postiMassimi, "c");
			this.$bvToast.toast(msg, { variant: "warning", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			val = postiMassimi;
		}
		this.datiViaggio.parametri.numeroPartecipanti = val;
		this.updateParametri(false);
		
		this.$root.$emit("changeNumeroPartecipanti");
	}

	updateNumeroPartecipantiNoCalcolo() {
		var val = parseInt(this.datiViaggio.parametri.numeroPartecipanti as any),
			postiMinimi = parseInt(helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["ParametroViaggio"], "PostiMinimi")) || 1,
			postiMassimi = parseInt(helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["ParametroViaggio"], "PostiMassimi")) || null;

		if (val < 0) val = 0;
		if (postiMinimi && val < postiMinimi) {
			val = postiMinimi;
		}
		if (postiMassimi && val > postiMassimi) {
			var msg = "L'importo impostato supera il massimo di " + kendo.toString(postiMassimi, "c");
			this.$bvToast.toast(msg, { variant: "warning", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			val = postiMassimi;
		}
		this.datiViaggio.parametri.numeroPartecipanti = val;

		if (this.datiViaggio.partecipanti.length != this.datiViaggio.parametri.numeroPartecipanti) {
			if (this.datiViaggio.partecipanti.length > this.datiViaggio.parametri.numeroPartecipanti) {
				var numeroPartecipanti = this.datiViaggio.parametri.numeroPartecipanti;
				var partecipanti = this.datiViaggio.partecipanti.length;
				for (var i = 0; i < partecipanti - numeroPartecipanti; i++) {
					this.datiViaggio.partecipanti.splice(-1);
				}
			}
			else {
				for (var i = this.datiViaggio.partecipanti.length; i < this.datiViaggio.parametri.numeroPartecipanti; i++) {
					this.datiViaggio.partecipanti.push(new PartecipantiCarrello());
				}
			}
		}
		
		this.$root.$emit("changeNumeroPartecipanti");
	}

	updateNumeroAlloggi() {
		if (!this.alloggiAbilitato()) {
			this.updateParametri(false);
			return;
		}
		this.datiViaggio.parametri.numeroAlloggi = 1;
		// var val = parseInt(this.datiViaggio.parametri.numeroAlloggi as any);

		// if (val <= 0) {
		// 	var msg = "Il numero degli alloggi deve essere minimo 1";
		// 	this.$bvToast.toast(msg, { variant: "warning", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
		// 	val = 1;
		// }
		// this.datiViaggio.parametri.numeroAlloggi = val;
		this.updateParametri(false);
	}

	onRemoveCodicePromo() {
		this.datiViaggio.parametri.codicePromo = "";
		this.calcolaPreventivo();
	}

	async calcolaPreventivo() {
		if (this.nonCalcolarePreventivo) return;
		if (!this.validaDatiPreventivo()) return;

		var data = this.getDati();

		this.cannotChangeParams = true;
		var tot = await api.chiamataPost<totaliCarrello>(_ENDPONT_TOTALI + "/" + this.datiViaggio.itemID, data);

		var complementari = await api.chiamataPost<CarrelloInfoProdotto[]>(_ENDPONT_COMPLEMENTARI, data);

		/*this.datiViaggio = helpers.cloneObject(data );
		this.datiViaggio.nazionalita = (this.parametri.nazionalita as any ).text as string|| '';
		this.datiViaggio.destinazione = (this.parametri.destinazione as any ).text as string|| '';*/

		this.datiViaggio.complementari = complementari.data || [];
		this.datiViaggio.totali = tot.data;

		//this.calcolaTotaliComplementari(tot.data);
	}

	setDatiViaggio(datiViaggio) {
		this.datiViaggio = datiViaggio
	}

	validaDatiPreventivo(): boolean {
		var dati = this.getDati(),
			err = [];

		if (!dati.inizioViaggio) err.push("Data Inizio Viaggio richiesta");

		if (!dati.fineViaggio) err.push("Data Fine Viaggio richiesta");

		if (!dati.statoID) err.push("Destinazione richiesta");

		if (!dati.nazionalitaID) err.push("Area di partenza richiesta");

		if (this.importoViaggioAbilitato() && !this.secondoImportoAbilitato() && parseFloat(dati.importoViaggio as any) < 1) err.push("Importo Viaggio richiesto");

		//if (this.secondoImportoAbilitato() && parseFloat(dati.secondoImporto as any) < 1) err.push("Importo ad Imbarcazione richiesto");
		if (this.secondoImportoAbilitato() && parseFloat(dati.secondoImporto as any) < 1  && parseFloat(dati.importoViaggio as any) < 1) err.push("Importo viaggio richiesto");

		if (this.alloggiAbilitato() && parseInt(dati.numeroAlloggi as any) < 1) err.push("Numero Alloggi richiesto");

		if (!dati.numeroPartecipanti) err.push("Numero Partecipanti richiesto");

		for (var i = 0; i < err.length; i++) {
			this.$bvToast.toast(err[i], { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
		}

		return err.length == 0;
	}

	validaDati() {
		var err = this.validaElencoPartecipanti() || [];

		if( this.$refs.grigliaulterioriDati ){
			err = err.concat( (this.$refs.grigliaulterioriDati as any).validaDati() );
		}

		for (var i = 0; i < err.length; i++) {
			this.$bvToast.toast(err[i], { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
		}

		return err.length == 0;
	}

	validaElencoPartecipanti() {
		var partecipanti = this.datiViaggio.partecipanti || [],
			err = [];

		if (!partecipanti.length) {
            err.push("Nessun partecipante presente");
        }

        var numPartecipanti = this.getDati().numeroPartecipanti || 0;

        if (partecipanti.length && partecipanti.length !== numPartecipanti) {
            err.push("Il numero dei partecipanti nella lista non corrisponde al numero indicato nel campo corrispondente");
        }

		for (var i = 0; i < partecipanti.length; i++) {
			var p = partecipanti[i];
			p.Nome = p.Nome ? p.Nome.trim() : "";
			p.Cognome = p.Cognome ? p.Cognome.trim() : "";
		}

		var dati = partecipanti,
			imgPartecipanti = this.datiViaggio.immaginePartecipanti,
			txtPartecipanti = imgPartecipanti ? 'il capogruppo' : 'tutti i partecipanti',
			regexPartec = new RegExp(/^[a-zA-Z\- 'òàùéèì']+$/);

		for (let i = 0; i < dati.length; i++) {
			var p = dati[i];
			if (!p.Cognome) {
				err.push("Cognome richiesto per " + txtPartecipanti);
				break;
			}

			if (p.CodiceFiscale && p.CodiceFiscale.length > 16) {
				err.push("Il codice fiscale del passeggero " + (p.Nome || "") + " " + (p.Cognome || "") + " ha piu' di 16 caratteri. Correggerlo");
				break;
			}

			if( (p.Cognome && !regexPartec.test(p.Cognome)) || (p.Nome && !regexPartec.test(p.Nome)) ){
				err.push("Per nome e cognome sono ammessi solo caratteri alfabetici. Correggere il partecipante " + (p.Nome || "") + " " + (p.Cognome || "") );
				break;
			}

			if(imgPartecipanti) // se img partecipanti e' caricata, controllo solo il capogruppo
				break;
		}
		
		var nascitaRequired = helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.dataNascitaObbligatorio),
			codFiscaleRequired = helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.codFiscaleObbligatorio),
			etaMin = helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.limiteEtaMin),
			etaMax = helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.limiteEtaMax);

		etaMin = etaMin ? parseInt(etaMin.valore) || -1 : -1;
		etaMax = etaMax ? parseInt(etaMax.valore) || -1 : -1;

		if (nascitaRequired) {
			for (var i = 0; i < dati.length; i++) {
				var p = dati[i];
				if (!p.DataNascita) {
					err.push("Data di Nascita richiesta per " + txtPartecipanti);
					break;
				}
				if(imgPartecipanti) // se img partecipanti e' caricata, controllo solo il capogruppo
					break;
			}
		}

		if (codFiscaleRequired) {
			for (var i = 0; i < dati.length; i++) {
				var p = dati[i];
				if (!p.CodiceFiscale) {
					err.push("Codice Fiscale richiesto per " + txtPartecipanti);
					break;
				}
				if(imgPartecipanti) // se img partecipanti e' caricata, controllo solo il capogruppo
					break;
			}
		}

		// if (etaMin > 0 || etaMax > 0) {
		// 	for (var i = 0; i < dati.length; i++) {
		// 		var p = dati[i];
		// 		if (!p.DataNascita) {
		// 			err.push("Data di Nascita richiesta per " + txtPartecipanti);
		// 			break;
		// 		} else {
		// 			var dNascita = moment(p.DataNascita),
		// 				part = p.Nome + " " + p.Cognome,
		// 				age = moment().diff(dNascita, "years"); // ritorna età in anni
		// 			if (etaMin > 0 && age < etaMin) {
		// 				err.push("L'eta' del partecipante " + part + " e' inferiore all'eta' minima di " + etaMin + " anni");
		// 				break;
		// 			} else if (etaMax > 0 && age > etaMax) {
		// 				err.push("L'eta' del partecipante " + part + " e' superiore all'eta' massima di " + etaMax + " anni");
		// 				break;
		// 			}
		// 		}
		// 		if(imgPartecipanti) // se img partecipanti e' caricata, controllo solo il capogruppo
		// 			break;
		// 	}
		// }

		return err;
	}

	getDati(): ParametriCarrelloItem {
		var par = helpers.cloneObject(this.datiViaggio.parametri) as parametriPreventivatoreItem;
		
		var data = new ParametriCarrelloItem();
		data.statoID = par.destinazioneStato ? ((par.destinazioneStato as any).value) || null : null;
		data.nazionalitaID = par.nazionalita ? ((par.nazionalita as any).value as number) || null : null;
		data.inizioViaggio = par.inizioViaggio;
		data.fineViaggio = par.fineViaggio;
		data.importoViaggio = par.importoViaggio || 0;
		data.secondoImporto = par.secondoImporto || 0;
		data.numeroPartecipanti = par.numeroPartecipanti;
		data.pacchettoID = this.prodottoSelezionato.pacchettoID;
		data.codicePromo = par.codicePromo;
		if (this.alloggiAbilitato()) data.numeroAlloggi = par.numeroAlloggi;

		// TERMINARE GESTIONE ALLOGGI -> CHECK ANCHE VALIDITA
		return data;
	}

	creaInfoTotali() {
		// funzione che crea oggetto con totali
		var totali = {};
		totali["prodotto"] = this.datiViaggio.totali;

		var compl = (this.$refs.complementari as any) || [];
		//var compl = ( this.complementari as any ) || [];

		for (var i = 0; i < compl.length; i++) {
			if (compl[i].addComplemetare) totali["compl" + compl[i].complementare.pacchettoID] = compl[i].totali;
		}

		var totGenerale = {
			commissioni: 0,
			totaleViaggio: 0,
			totaleViaggioDaPagare: 0,
			totaleViaggioImposte: 0,
			totaleViaggioPersona: 0,
			totaleViaggioPersonaImposte: 0,
			scontoCoupon: 0
		};

		for (var n in totali) {
			if (!totali[n]) continue;
			totGenerale.commissioni += totali[n].commissioni;
			totGenerale.totaleViaggio += totali[n].totaleViaggio;
			totGenerale.totaleViaggioDaPagare += totali[n].totaleViaggioDaPagare;
			totGenerale.totaleViaggioImposte += totali[n].totaleViaggioImposte;
			totGenerale.totaleViaggioPersona += totali[n].totaleViaggioPersona;
			totGenerale.totaleViaggioPersonaImposte += totali[n].totaleViaggioPersonaImposte;
			totGenerale.scontoCoupon += totali[n].scontoCoupon;
		}

		totali["tot"] = totGenerale;

		return totali;
	}

	checkMaxTotPersona() {
		var maxTot = 200;
		if (!this.datiViaggio.totali || this.datiViaggio.totali.totaleViaggioPersona <= maxTot) return 0;

		if (this.prodottoSelezionato.controlloIddEscluso) return 0;

		var msg =
			"Attenzione: importo polizza superiore ad \u20ac 200,00 ( limite imposto dalla normativa IDD ). Non e' possibile procedere all'adesione." +
			"Contattaci all'indirizzo assistenza@webins.it.";

		this.$bvToast.toast(msg, { variant: "warning", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });

		return 2;
	}

	initDate() {
		var inizioViaggio = this.datiViaggio.parametri.inizioViaggio || helpers.getToday(), // valore iniziale se in modifica
			fineViaggio = this.datiViaggio.parametri.fineViaggio || helpers.getToday(), // valore iniziale se in modifica
			offsetDataMinima = this.prodottoSelezionato.offsetDataMinima || 0, // num giorni di offset
			dataMinima = new Date(helpers.getToday().getTime() + offsetDataMinima * 24 * 60 * 60 * 1000); // num giorni di offset

		if (helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.limite30GG)) {
			dataMinima = new Date(helpers.getToday().getTime() + 30 * 24 * 60 * 60 * 1000);
		}

		var dataMinimaParametro = helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.dataMinima),
			dataMassimaParametro = helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.dataMassima),
			dataMax = helpers.getMaxDate();

		if (dataMinimaParametro) dataMinima = moment(dataMinimaParametro.valore, "DD/MM/YYYY").toDate();

		if (dataMassimaParametro) dataMax = moment(dataMassimaParametro.valore, "DD/MM/YYYY").toDate();

		//this.inizioViaggioMin = dataMinima;
		//this.inizioViaggioMax = dataMax;

		this.datiViaggio.parametri.inizioViaggio = inizioViaggio ? moment(inizioViaggio).toDate() : dataMinima; // ritorna domani

		/// DATA FINE

		this.datiViaggio.parametri.fineViaggio = fineViaggio ? moment(fineViaggio).toDate() : dataMinima; // ritorna domani
		//this.fineViaggioMin = this.datiViaggio.parametri.inizioViaggio;
		//this.fineViaggioMax = dataMax;

		this.changeInizioViaggio();
	}

	changeInizioViaggio() {
		var valore = this.datiViaggio.parametri.inizioViaggio,
			dataMinima = this.inizioViaggioMin,
			durataMin = helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["ParametroViaggio"], "DurataMinima"),
			durataMax = helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["ParametroViaggio"], "DurataMassima");

		if (!valore || valore < dataMinima) {
			//this.datiViaggio.parametri.inizioViaggio = dataMinima;
			valore = dataMinima;
		}

		var daysToAdd = durataMin || 0;
		// this.fineViaggioMin = helpers.addDaysToDate(valore, daysToAdd);

		// if (durataMax){
		// 	// durata max - 1 per escludere il primo giorno
		// 	this.fineViaggioMax = helpers.addDaysToDate(valore, durataMax - 1);
		// }

		this.changeFineViaggio();

		//this.updateParametri(false);
	}

	changeFineViaggio() {
		var valore = this.datiViaggio.parametri.fineViaggio,
			inizioDate = this.datiViaggio.parametri.inizioViaggio,
			durataMin = helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["ParametroViaggio"], "DurataMinima"),
			durataMax = helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["ParametroViaggio"], "DurataMassima"),
			dataMin = helpers.addDaysToDate(inizioDate, durataMin || 0),
			dataMax = durataMax ? helpers.addDaysToDate(inizioDate, durataMax) : helpers.getMaxDate();

		if (dataMin > valore) {
			this.datiViaggio.parametri.fineViaggio = dataMin;
		} else if (dataMax && valore > helpers.addDaysToDate(dataMax, -1)) {
			//Toast.show("Le date non rispettano la durata massima del viaggio", { type: "error" });
			//this.datiViaggio.parametri.fineViaggio = helpers.addDaysToDate(dataMax, -1);
		}

		this.updateParametri(false);
	}

	changeInizioViaggioNoCalcolo() {
		var valore = this.datiViaggio.parametri.inizioViaggio,
			dataMinima = this.inizioViaggioMin,
			durataMin = helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["ParametroViaggio"], "DurataMinima"),
			durataMax = helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["ParametroViaggio"], "DurataMassima");

		if (!valore || valore < dataMinima) {
			//this.datiViaggio.parametri.inizioViaggio = dataMinima;
			valore = dataMinima;
		}

		var daysToAdd = durataMin || 0;
		// this.fineViaggioMin = helpers.addDaysToDate(valore, daysToAdd);

		// if (durataMax){
		// 	// durata max - 1 per escludere il primo giorno
		// 	this.fineViaggioMax = helpers.addDaysToDate(valore, durataMax - 1);
		// }

		this.changeFineViaggio();

	}

	changeFineViaggioNoCalcolo() {
		var valore = this.datiViaggio.parametri.fineViaggio,
			inizioDate = this.datiViaggio.parametri.inizioViaggio,
			durataMin = helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["ParametroViaggio"], "DurataMinima"),
			durataMax = helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["ParametroViaggio"], "DurataMassima"),
			dataMin = helpers.addDaysToDate(inizioDate, durataMin || 0),
			dataMax = durataMax ? helpers.addDaysToDate(inizioDate, durataMax) : helpers.getMaxDate();

		if (dataMin > valore) {
			this.datiViaggio.parametri.fineViaggio = dataMin;
		} else if (dataMax && valore > helpers.addDaysToDate(dataMax, -1)) {
			//Toast.show("Le date non rispettano la durata massima del viaggio", { type: "error" });
			//this.datiViaggio.parametri.fineViaggio = helpers.addDaysToDate(dataMax, -1);
		}
	}
	
	onModificaPratica(){
		this.modificaPratica(false);
	}

	onModificaPraticaConEmail(){
		this.modificaPratica(true);
	}

	async modificaPratica(conEmail) {
		var self = this;

		return new Promise(async function (resolve, reject) {
			if (self.aggiungiACarrelloClick || !self.validaDatiPreventivo() || !self.validaDati()) {
				reject('errore validazione');
				return;
			}

			self.timeoutClick = setTimeout(() => {
				self.aggiungiACarrelloClick = false
			}, 5000);

			self.aggiungiACarrelloClick = true;

			if (!confirm("Procedere con la modifica della pratica?"))
            	return;

			var data = self.buildDatiPerCarrello();

			data.itemID = self.datiViaggio.itemID;

			var tot = await api.chiamataPost<totaliCarrello>(_ENDPONT_TOTALI + "/" + data.itemID, data);
			self.datiViaggio.totali = tot.data;

			var infoTotali = self.creaInfoTotali();
			data.infoTotali = infoTotali ? btoa(JSON.stringify(infoTotali)) : null;

			debugger;
			
			api.chiamataPost(_ENDPONT_MODIFICA_PRATICA + ("?invioemail=" + (conEmail ? true : false)), data)
			.then((res) => {
				if( !res){
					self.$bvToast.toast("Errore durante la modifica della pratica", { variant: "danger", title: "Errore Pratica", solid: true });
					reject('errore modifica pratica');
					clearTimeout(self.timeoutClick);
					self.aggiungiACarrelloClick = false;
					return;
				}
	
				clearTimeout(self.timeoutClick);
				self.aggiungiACarrelloClick = true;
				self.$bvToast.toast("Pratica inviata con successo!", { variant: "success", title: "Pratica inviata", solid: true });
			
				resolve('ok');
	
				setTimeout(() => {
					self.$router.push({ path: "/gestione-vendite/ricerca-garanzie" });
				}, 500);
	
				resolve(res);
			}).catch(err => {
				reject(err);
			});


			
		});
	}

	get destinazioneID(): number { 
		return (this.datiViaggio.parametri.destinazioneStato as any || {}).value || null;
	}
	get nazionalitaID(): number { 
		return (this.datiViaggio.parametri.destinazioneStato as any || {}).value || null;
	}
	buildDatiPerCarrello(): RigaCarrello {
		var par = helpers.cloneObject(this.datiViaggio.parametri) as parametriPreventivatoreItem;

		var data = new RigaCarrello();
		
		data.pacchettoID = this.prodottoSelezionato.pacchettoID;
		data.inizioViaggio = par.inizioViaggio;
		data.fineViaggio = par.fineViaggio;
		data.numeroPartecipanti = par.numeroPartecipanti;
		data.statoID = par.destinazioneStato ? ((par.destinazioneStato as any).value as number) || null : null;
		data.nazionalitaID = par.nazionalita ? ((par.nazionalita as any).value as number) || null : null;

		data.importoViaggio = this.importoViaggioAbilitato() ? par.importoViaggio || 0 : 0;
		data.secondoImporto = this.secondoImportoAbilitato() ? par.secondoImporto || 0 : 0; // DA GESTIRE POI
		data.numeroAlloggi = this.alloggiAbilitato() ? par.numeroAlloggi : 0; // DA GESTIRE POI

		data.note = this.note || "";
		data.riferimentoPratica = this.rifPratica || "";

		var part = this.datiViaggio.partecipanti || [];

		data.elencoPartecipanti = btoa(JSON.stringify(part));
		data.tesseraSingola = this.datiViaggio.tesseraSingola || false;
		data.imageFilePartecipanti = this.datiViaggio.immaginePartecipanti;

		data.codicePromo = par.codicePromo || "";

		data.adeguatezza = this.datiViaggio.parametriDichiarazione.adeguatezza || null;

		data.ulterioriDati = this.datiViaggio.ulterioriDati && this.datiViaggio.ulterioriDati.length ? btoa( JSON.stringify( this.datiViaggio.ulterioriDati) ) : null

		//var pCollegati = JSON.stringify(this.datiViaggio.prodottiCollegati || []);
		//data.prodottiCollegati = pCollegati;
		return data;
	}

	scrollToTop() {
		var body = this.$el.parentElement.parentElement;
		body.scrollTop = 0;
	}

	onIndietro() {
		this.$router.go(-1);
		//this.$router.push({ path: "listaProdotti" as string });
	}

	handleScroll(event) {
		try {
			var divPolizzeContainer = document.getElementsByClassName("polizze-integrative-container")[0];
			if (divPolizzeContainer) {
				var elemRect = divPolizzeContainer.getBoundingClientRect();

				var elementToScroll = document.getElementsByClassName("totale-premio-container")[0];

				if (elemRect.top - 180 < 0) {
					elementToScroll.classList.add("box-riepilogo-totali-fixed");
				} else {
					elementToScroll.classList.remove("box-riepilogo-totali-fixed");
				}
			}
		} catch (ex) {
			console.log("errore nella gestione dello scroll del pannello dei totali");
			console.log(ex);
		}
	}

	updateTesseraSingola() {
		this.datiViaggio.tesseraSingola = this.tesseraSingola;
		this.setDatiViaggio(this.datiViaggio);
	}
}
